<template lang="pug">
main
    a.whatsappWrapper(href="https://wa.me/+917579175791?text=I'm%20inquiring%20about%20the%20properties%20from%20Website!")
            img(src='../assets/img/WhatsApp.svg')
    display-header(:img="require('@/assets/overscans/events-header.jpg')",headline="Events")
    .container
        left-side-section(
            title="Chess"
            :img='require("@/assets/img/real_site_images/resized/chess-3.jpg")'
            description=""
        )
            | A chess extravaganza that captured hearts and minds! This image encapsulates the essence of the event, where strategic brilliance met competitive spirit. Participants and spectators alike were treated to unforgettable moments of chess excellence.

        right-side-section(
            title="Tennis"
            :img='require("@/assets/img/real_site_images/resized/tennis-2.jpg")'
            description=" A tennis event that served up unforgettable action, competition, and camaraderie! This snapshot offers a sneak peek into the world of tennis enthusiasts who came together for a day of smashing rallies and spirited play."
        )
        
        .img-grid
            .cell
                img(:src='require("@/assets/img/real_site_images/resized/tennis-2.jpg")')
            .cell
                img(:src='require("@/assets/img/real_site_images/resized/award-1.jpg")')
            .cell
                img(:src='require("@/assets/img/real_site_images/resized/chess-1.jpg")')
            .cell
                img(:src='require("@/assets/img/real_site_images/resized/chess-2.jpg")')
            .cell
                img(:src='require("@/assets/img/real_site_images/resized/award-2.jpg")')
            .cell
                img(:src='require("@/assets/img/real_site_images/resized/award-3.jpg")')
            .cell
                img(:src='require("@/assets/img/real_site_images/resized/award-4.jpg")')
    location
</template>
    
    <script>
    
    // Imports
    import DisplayHeader from '../components/DisplayHeader.vue'
    import RightSideSection from '../components/XRightSideSection.vue'
    import LeftSideSection from '../components/XLeftSideSection.vue'
    import FullImageSection from '../components/XFullImageSection.vue'
    // Vue Config
    export default {
         name: "",
      components: {DisplayHeader, RightSideSection, LeftSideSection, FullImageSection},
    };
    </script>
    
    <style scoped>
    .img-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    .cell {
        width: 100%;
        aspect-ratio: 1;
    }

    .cell img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media(max-width:600px) {
        .img-grid {
            grid-template-columns: 1fr;
        }
    }
    </style>